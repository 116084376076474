import {
    DashboardIcon,
    DocumentStampIcon,
    HomeIcon,
    InvoiceIcon,
    MenuItemProps,
    MoneyIcon,
    StockIcon,
    UserIcon,
    WrenchHammerIcon,
} from '@keyliving/component-lib';
import { lazy, Suspense } from 'react';
import { Navigate, RouteObject } from 'react-router-dom';

import FullScreenFallbackLayout from '../components/layout/FullScreenFallbackLayout';
import NoMatch from '../pages/NoMatch';
import { URLS } from './urls';

const Application = lazy(() => import('../pages/Applications/Application'));
const Applications = lazy(() => import('../pages/Applications/Applications'));
const Asset = lazy(() => import('../pages/Assets/Asset'));
const Assets = lazy(() => import('../pages/Assets/Assets'));
const AssetCreate = lazy(() => import('../pages/Assets/AssetCreate'));
const Building = lazy(() => import('../pages/Buildings/Building'));
const Buildings = lazy(() => import('../pages/Buildings/Buildings'));
const BuildingCreate = lazy(() => import('../pages/Buildings/BuildingCreate'));
const CapTables = lazy(() => import('../pages/CapTables/CapTables'));
const Dashboard = lazy(() => import('../pages/Dashboard'));
const Invoice = lazy(() => import('../pages/Invoices/Invoice'));
const Invoices = lazy(() => import('../pages/Invoices/Invoices'));
const InvoicesCsvDownloadIndex = lazy(() => import('../pages/Invoices/InvoicesCsvDownloadIndex'));
const OwnerPaymentsIndex = lazy(() => import('../pages/Invoices/OwnerPaymentsIndex'));
const OwnerPayments = lazy(() => import('../pages/Invoices/OwnerPayments'));
const InvoicesCsvDownload = lazy(() => import('../pages/Invoices/InvoicesCsvDownload'));
const InvoicesIndex = lazy(() => import('../pages/Invoices/InvoicesIndex'));
const InvoicesMonthlyUpload = lazy(() => import('../pages/Invoices/InvoicesMonthlyUpload'));
const InvoiceCreate = lazy(() => import('../pages/Invoices/InvoiceCreate'));
const Users = lazy(() => import('../pages/Users/Users'));
const CreateUser = lazy(() => import('../pages/Users/Create'));
const User = lazy(() => import('../pages/Users/User'));
const Products = lazy(() => import('../pages/Products/Products'));
const ProductCreate = lazy(() => import('../pages/Products/Create'));
const ReimbursementAdjustmentItems = lazy(
    () => import('../pages/ReimbursementAdjustment/ReimbursementAdjustmentItems')
);
const UnderwritingURLCreator = lazy(() => import('../pages/Utility/UnderwritingURLCreator'));
const ReferenceExperiment = lazy(() => import('../pages/Utility/ReferenceExperiment'));
const MessageQueueAdmin = lazy(() => import('../pages/Utility/MessageQueueAdmin'));
const TasksScheduledAcknowledgedAdmin = lazy(
    () => import('../pages/Utility/TasksScheduledAcknowledgedAdmin')
);

/**
 * These are public routes that the app needs. Because we currently only show the menu to
 * users who are logged in, there is no need for these to be in the menu. These are used
 * in App.tsx to create these routes.
 */
export const publicRoutes: RouteObject[] = [
    {
        path: '/login',
        element: <Navigate to="/" />,
    },
    {
        path: '*',
        element: <NoMatch />,
    },
];

/**
 * These are routes for logged in user only. We use this array to build our menu but
 * don't use these directly to create the routes.
 */
export const authRoutes: MenuItemProps[] = [
    {
        path: '/',
        label: 'Dashboard',
        icon: <DashboardIcon height={24} width={24} />,
        showInMenu: true,
        index: true,
        element: (
            <Suspense fallback={<FullScreenFallbackLayout isLoading />}>
                <Dashboard />
            </Suspense>
        ),
    },
    {
        path: URLS.Applications.path,
        showInMenu: true,
        label: 'Applications',
        icon: <DocumentStampIcon height={24} width={24} />,
        children: [
            {
                index: true,
                element: (
                    <Suspense fallback={<FullScreenFallbackLayout isLoading />}>
                        <Applications />
                    </Suspense>
                ),
            },
            {
                path: URLS.Applications.Detail.path,
                element: (
                    <Suspense fallback={<FullScreenFallbackLayout isLoading />}>
                        <Application />
                    </Suspense>
                ),
            },
        ],
    },
    {
        path: URLS.CapTables.path,
        showInMenu: true,
        icon: <StockIcon height={24} width={24} />,
        label: 'Cap Tables',
        children: [
            {
                index: true,
                element: (
                    <Suspense fallback={<FullScreenFallbackLayout isLoading />}>
                        <CapTables />
                    </Suspense>
                ),
            },
        ],
    },
    {
        path: URLS.Database.path,
        label: 'Homes',
        icon: <HomeIcon height={24} width={24} />,
        showInMenu: true,
        children: [
            {
                index: true,
                element: <Navigate to={URLS.Database.Assets.path} />,
            },
            {
                path: URLS.Database.Assets.path,
                label: 'Assets',
                showInMenu: true,
                children: [
                    {
                        index: true,
                        element: (
                            <Suspense fallback={<FullScreenFallbackLayout isLoading />}>
                                <Assets />
                            </Suspense>
                        ),
                    },
                    {
                        path: URLS.Database.Assets.Detail.path,
                        element: (
                            <Suspense fallback={<FullScreenFallbackLayout isLoading />}>
                                <Asset />
                            </Suspense>
                        ),
                    },
                    {
                        path: URLS.Database.Assets.Create.path,
                        showInMenu: true,
                        label: 'Create',
                        element: (
                            <Suspense fallback={<FullScreenFallbackLayout isLoading />}>
                                <AssetCreate />
                            </Suspense>
                        ),
                    },
                ],
            },
            {
                path: URLS.Database.Buildings.path,
                label: 'Buildings',
                showInMenu: true,
                children: [
                    {
                        index: true,
                        element: (
                            <Suspense fallback={<FullScreenFallbackLayout isLoading />}>
                                <Buildings />
                            </Suspense>
                        ),
                    },
                    {
                        path: URLS.Database.Buildings.Create.path,
                        element: (
                            <Suspense fallback={<FullScreenFallbackLayout isLoading />}>
                                <BuildingCreate />
                            </Suspense>
                        ),
                    },
                    {
                        path: URLS.Database.Buildings.Detail.path,
                        element: (
                            <Suspense fallback={<FullScreenFallbackLayout isLoading />}>
                                <Building />
                            </Suspense>
                        ),
                    },
                ],
            },
            {
                path: URLS.Database.Owners.path,
                label: 'Owners',
            },
            {
                path: URLS.Database.ReimbursementAdjustment.path,
                label: 'Reimbursement & Adjustment',
                showInMenu: true,
                children: [
                    {
                        index: true,
                        element: (
                            <Suspense fallback={<FullScreenFallbackLayout isLoading />}>
                                <ReimbursementAdjustmentItems />
                            </Suspense>
                        ),
                    },
                ],
            },
        ],
    },
    {
        path: URLS.Invoices.path,
        showInMenu: true,
        icon: <InvoiceIcon height={24} width={24} />,
        label: 'Invoices',
        children: [
            {
                index: true,
                element: (
                    <Suspense fallback={<FullScreenFallbackLayout isLoading />}>
                        <InvoicesIndex />
                    </Suspense>
                ),
            },
            {
                path: URLS.Invoices.ByMonth.path,
                element: (
                    <Suspense fallback={<FullScreenFallbackLayout isLoading />}>
                        <Invoices />
                    </Suspense>
                ),
            },
            {
                path: URLS.Invoices.Csv.path,
                showInMenu: true,
                label: 'Invoice Download',
                children: [
                    {
                        index: true,
                        element: (
                            <Suspense fallback={<FullScreenFallbackLayout isLoading />}>
                                <InvoicesCsvDownloadIndex />
                            </Suspense>
                        ),
                    },
                    {
                        path: URLS.Invoices.Csv.ByMonth.path,
                        element: (
                            <Suspense fallback={<FullScreenFallbackLayout isLoading />}>
                                <InvoicesCsvDownload />
                            </Suspense>
                        ),
                    },
                ],
            },
            {
                path: URLS.Invoices.OwnerPayments.path,
                showInMenu: true,
                label: 'Owner Payments',
                children: [
                    {
                        index: true,
                        element: (
                            <Suspense fallback={<FullScreenFallbackLayout isLoading />}>
                                <OwnerPaymentsIndex />
                            </Suspense>
                        ),
                    },
                    {
                        path: URLS.Invoices.OwnerPayments.Csv.ByMonth.path,
                        element: (
                            <Suspense fallback={<FullScreenFallbackLayout isLoading />}>
                                <OwnerPayments />
                            </Suspense>
                        ),
                    },
                ],
            },
            {
                path: URLS.Invoices.Detail.path,
                element: (
                    <Suspense fallback={<FullScreenFallbackLayout isLoading />}>
                        <Invoice />
                    </Suspense>
                ),
            },
            {
                path: URLS.Invoices.Create.path,
                showInMenu: false,
                label: 'Create',
                element: (
                    <Suspense fallback={<FullScreenFallbackLayout isLoading />}>
                        <InvoiceCreate />
                    </Suspense>
                ),
            },
            {
                path: URLS.Invoices.MonthlyUpload.path,
                showInMenu: true,
                label: 'Monthly Upload',
                element: (
                    <Suspense fallback={<FullScreenFallbackLayout isLoading />}>
                        <InvoicesMonthlyUpload />
                    </Suspense>
                ),
            },
        ],
    },
    {
        path: URLS.Products.path,
        showInMenu: true,
        icon: <MoneyIcon height={24} width={24} />,
        label: 'Products',
        children: [
            {
                index: true,
                element: (
                    <Suspense fallback={<FullScreenFallbackLayout isLoading />}>
                        <Products />
                    </Suspense>
                ),
            },
            {
                path: URLS.Products.Create.path,
                showInMenu: false,
                element: (
                    <Suspense fallback={<FullScreenFallbackLayout isLoading />}>
                        <ProductCreate />
                    </Suspense>
                ),
            },
        ],
    },
    {
        path: URLS.Users.path,
        label: 'Users',
        icon: <UserIcon height={24} width={24} />,
        showInMenu: true,
        children: [
            {
                index: true,
                element: (
                    <Suspense fallback={<FullScreenFallbackLayout isLoading />}>
                        <Users />
                    </Suspense>
                ),
            },
            {
                path: URLS.Users.Detail.path,
                element: (
                    <Suspense fallback={<FullScreenFallbackLayout isLoading />}>
                        <User />
                    </Suspense>
                ),
            },
            {
                path: URLS.Users.Create.path,
                showInMenu: false,
                element: (
                    <Suspense fallback={<FullScreenFallbackLayout isLoading />}>
                        <CreateUser />
                    </Suspense>
                ),
            },
        ],
    },
    {
        path: URLS.Utility.path,
        showInMenu: true,
        icon: <WrenchHammerIcon height={24} width={24} />,
        label: 'Utility',
        children: [
            {
                index: true,
                element: <Navigate to={URLS.Utility.UnderwritingURLCreator.path} />,
            },
            {
                showInMenu: true,
                label: 'Underwriting URL Creator',
                path: URLS.Utility.UnderwritingURLCreator.path,
                element: (
                    <Suspense fallback={<FullScreenFallbackLayout isLoading />}>
                        <UnderwritingURLCreator />
                    </Suspense>
                ),
            },
            {
                showInMenu: true,
                label: 'Reference Experiment',
                path: URLS.Utility.ReferenceExperiment.path,
                element: (
                    <Suspense fallback={<FullScreenFallbackLayout isLoading />}>
                        <ReferenceExperiment />
                    </Suspense>
                ),
            },
            {
                showInMenu: true,
                label: 'Message Queue',
                path: URLS.Utility.MessageQueueAdmin.path,
                element: (
                    <Suspense fallback={<FullScreenFallbackLayout isLoading />}>
                        <MessageQueueAdmin />
                    </Suspense>
                ),
            },
            {
                showInMenu: true,
                label: 'Tasks Scheduled Acknowledged',
                path: URLS.Utility.TasksScheduledAcknowledgedAdmin.path,
                element: (
                    <Suspense fallback={<FullScreenFallbackLayout isLoading />}>
                        <TasksScheduledAcknowledgedAdmin />
                    </Suspense>
                ),
            },
        ],
    },
    {
        path: URLS.Profile.path,
        element: <div>Profile Here</div>,
    },
];
